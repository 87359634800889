import React, { useEffect } from 'react'
import IndexLayout from '../layouts'
import InvestorForm from '../components/shared/InvestorForm'

const word = 'Hello'

const InvestorsPage = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://documentcloud.adobe.com/view-sdk/main.js'
    const domContainer = document.querySelector('#adobe-script')
    domContainer?.appendChild(script)

    document.addEventListener('adobe_dc_view_sdk.ready', () => {
      const adobeDCView = new AdobeDC.View({
        clientId: process.env.GATSBY_ADOBE_CLIENT_KEY || '5ae194b79f8545128afb2d12672a11aa',
        divId: 'adobe-dc-view'
      })
      adobeDCView.previewFile(
        {
          content: { location: { url: '/investor_deck.pdf' } },
          metaData: { fileName: 'investor_deck.pdf' }
        },
        { embedMode: 'IN_LINE' }
      )
    })
  }, [])

  return (
    <IndexLayout>
      <div className="container">
        <div className="row mx-0">
          <div className="col-12 text-center pt-5">
            <h3>Right now is the best time in decades to launch a food business</h3>
          </div>
          <div className="col-12">
            <ul>
              <li>Customers are already considering new ways to shop for food, but haven't found their grocery alternative yet.</li>
              <li>
                Coronavirus is accelerating change in a way we'll never see again in our lifetime. Right now is the perfect opportunity to
                change customer behavior.
              </li>
              <li>
                As a wave of restaurants go out of business food spaces and employees will be cheap reducing initial operational costs
              </li>
            </ul>
          </div>
          <div className="col-12 d-flex justify-content-center pt-5 px-0">
            <div id="adobe-dc-view" style={{ width: '800px' }} />
            <div id="adobe-script" />
          </div>
          <div className="col-12">
            <InvestorForm />
          </div>
        </div>
      </div>
      {process.env.GATSBY_ENVIRONMENT === 'production' && (
        <script async src="https://s3-us-west-2.amazonaws.com/files.getemails.com/account/DY4HEXK/source/getemails.js" />
      )}
    </IndexLayout>
  )
}

export default InvestorsPage
